//
// Layouts
//

// Navbar

.navbar {
	.navbar-brand {
		margin-left: auto;
		margin-right: auto;
		color: $primary;
	}
}

.navbar-nav {
	margin-top: $grid-gutter-width;
	
	.nav-item {
		border-bottom: solid $border-width rgba($primary, .25);
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		&:not(:hover),
		&:not(:focus) {
			color: $body-color;
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar {
		&,
		> .container,
		> .container-fluid {
			flex-direction: column;
		}
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: flex !important;
		flex-basis: auto;
		flex-grow: inherit;
		position: static;
		padding-left: 0;
		padding-right: 0;
	}
	
	.navbar-nav {
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		
		.nav-item {
			border-bottom: 0;
		}
		
		.nav-link {
			position: relative;
			padding: $nav-link-padding-y $nav-link-padding-x;
			
			&:hover,
			&:focus {
				color: shade-color($secondary, $link-shade-percentage);
			}
			
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 0;
				height: 2px;
				background-color: $secondary;
				@include transition($transition-base);
				transform: translateX(-50%);
			}
			
			&:hover::before,
			&:hover::after {
				width: 100%;
			}
		}
	}
}

// Footer

.footer {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
	background-color: $gray-100;
	@include font-size($font-size-sm);
	
	.navbar-brand {
		display: block;
		margin-bottom: $paragraph-margin-bottom * .5;
		color: $primary;
	}
	
	.row {
		.section {
			@include make-col-ready();
		}
	}
	
	@include media-breakpoint-up(md) {
		.row {
			.section {
				margin-top: 0 !important;
				@include make-col(4, $grid-columns);
			}
		}
	}
}

.credit {
	padding-top: $paragraph-margin-bottom;
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}


// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}
